<div class="app-double-glow"
     [class.app-double-glow--outer-shadow]="outerShadow"
     [class.app-double-glow--size-xs]="size === 'xs'"
     [class.app-double-glow--size-sm]="size === 'sm'"
     [class.app-double-glow--size-l]="size==='l'"
     [class.app-double-glow--size-xl]="size==='xl'"
     [class.app-double-glow--size-xxl]="size==='xxl'"
     [class.app-double-glow--size-giga]="size==='giga'"
     [class.app-double-glow--size-80]="size==='80'"
     [class.app-double-glow--img-cover]="imgCover"
     [class.app-double-glow--circle]="circle"
     [class.app-double-glow--icon-half]="iconIsHalf"
     [class.app-double-glow--icon-twothird]="iconIsTwoThird"
     [class.app-double-glow--color-yellow]="bgColor === 'yellow'"
     [class.app-double-glow--color-gold]="bgColor === 'gold'"
     [class.app-double-glow--color-silver]="bgColor === 'silver'"
     [class.app-double-glow--color-white]="bgColor === 'white'"
     [class.app-double-glow--color-turquoise]="bgColor === 'turquoise'"
>
  <div class="app-double-glow__effect">
    <ng-content></ng-content>
  </div>
</div>
