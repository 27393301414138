@if (ranking) {
  <div class="app-medal"
       [class.app-medal--small]="small">

    <app-double-glow [circle]="true" [bgColor]="ranking === rankingEnum.Championship ? 'gold' : 'silver'">
      <div class="app-medal__content">
        @if (ranking === rankingEnum.First) {
          <span>1</span>
          <span class="app-medal__suffix"> {{ "rankings.suffix-first" | translate }}</span>
        } @else if (ranking === rankingEnum.Second) {
          <span>2</span>
          <span class="app-medal__suffix"> {{ "rankings.suffix-second" | translate }}</span>
        } @else if (ranking === rankingEnum.Third) {
          <span>3</span>
          <span class="app-medal__suffix"> {{ "rankings.suffix-third" | translate }}</span>
        } @else if (ranking === rankingEnum.Fourth) {
          <span>4</span>
          <span class="app-medal__suffix"> {{ "rankings.suffix-fourth" | translate }}</span>
        } @else if (ranking === rankingEnum.Championship) {
          <span>Ch</span>
          <!-- <img src="/assets/icons/champion.svg" width="18" height="9" alt=""> -->
        }
      </div>
    </app-double-glow>
  </div>
}
