import {Component, Input, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-double-glow',
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  imports: [],
  templateUrl: './double-glow.component.html',
  styleUrl: './double-glow.component.scss'
})
export class DoubleGlowComponent {
  @Input() bgColor: "gold" | "silver" | "yellow" | "white" | "turquoise" | null = null;
  @Input() size: 'xs' | 'sm' | 'm' | 'l' | 'xl' | 'xxl' | 'giga' | '80' = 'm';
  @Input() iconIsHalf = false;
  @Input() iconIsTwoThird = false;
  @Input() imgCover = false;
  @Input() circle = false;
  @Input() outerShadow = true;
}
