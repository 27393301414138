import {Component, Input} from '@angular/core';
import {BandmanagerRestApiSectionEnum} from "@digitale-menschen/bandmanager-rest-api";
import {TranslateModule} from "@ngx-translate/core";
import {DoubleGlowComponent} from "../double-glow/double-glow.component";

@Component({
  selector: 'app-medal',
  standalone: true,
  imports: [
    TranslateModule,
    DoubleGlowComponent
  ],
  templateUrl: './medal.component.html',
  styleUrl: './medal.component.scss'
})
export class MedalComponent {
  @Input({required: true}) ranking?: BandmanagerRestApiSectionEnum | string;
  @Input() small = false;
  protected readonly rankingEnum = BandmanagerRestApiSectionEnum;
}
